export const {
  IS_DEBUG,
  BACKEND_ADDRESS,
  PUBLIC_PATH,
  GIT_HASH,
} = APP_CONSTS;


export const LOG_LEVEL_LS = "LOG_LEVEL_LS";
export const PAGINATION_SIZE = 10;
